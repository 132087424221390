import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/wrapper';

const AboutPage = () => (
  <Layout>
    <SEO title="Python Web Developer" />
    <Wrapper>
      <p>
        I'm currently a Solutions Architect{' '}
        <a href="https://melonusa.com">Melon</a> in Apex, NC. My primary focus
        is custom web application development with Python and React.
      </p>
      <p>
        <a href="https://github.com/mlavin/">My Github account</a> contains an
        number of open source projects in various states of progress. Most are
        related to the Django framework. These include proof of concepts,
        example code from past articles or talks, and projects which have seen
        production use in both current and past projects. Below are some of the
        highlights.
      </p>
      <h3>Open Source Work Highlights</h3>
      <article>
        <ul>
          <li>
            <a href="https://github.com/mlavin/cloudping">cloudping</a> -
            Website uptime monitor which runs on the AWS free tier
          </li>
          <li>
            <a href="https://github.com/mlavin/django-selectable">
              django-selectable
            </a>{' '}
            - Easy Django integration with the jQuery UI autocomplete
          </li>
          <li>
            <a href="https://github.com/mlavin/django-all-access">
              django-all-access
            </a>{' '}
            - User registration and authentication from OAuth 1.0 and OAuth 2.0
            providers
          </li>
          <li>
            <a href="https://github.com/mlavin/django-responsive">
              django-responsive
            </a>{' '}
            - Utilities for building responsive websites in Django
          </li>
          <li>
            <a href="https://github.com/mlavin/django-ad-code">
              django-ad-code
            </a>{' '}
            - Utilities for managing and rendering third-party ad tags in
            Django.
          </li>
          <li>
            <a href="https://github.com/caktus/django-sticky-uploads">
              django-sticky-uploads
            </a>{' '}
            - Enhanced file input widget for Django which retains its value on
            form errors
          </li>
          <li>
            <a href="https://github.com/caktus/django-scribbler">
              django-scribbler
            </a>{' '}
            - Small Django-based CMS with front-end editing capabilities
          </li>
          <li>
            <a href="https://github.com/mlavin/sickmuse">sickmuse</a> - A
            web-based graphing front-end for Collectd
          </li>
        </ul>
      </article>
      <p>
        My GPG key ID 3123FC13 is verified on{' '}
        <a href="https://keybase.io/mlavin">Keybase</a>.
      </p>
    </Wrapper>
  </Layout>
);

export default AboutPage;
